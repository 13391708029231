import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

const StyledHeroImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
`

const StyledContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`

const StyledSectionTitle = styled("h2")`
  color: #da1e35;
  font-size: 32px;
  text-align: center;
  padding: 0 20px;
`

const StyledAboutCompanySection = styled("div")`
  display: flex;
  flex-direction: column;

  p {
    color: #000;
    font-size: 15px;
    padding: 0 20px;
    text-align: justify;
  }
`

const StyledLocationImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
  max-width: 530px;
`

const StyledMagnifierImage = styled(Image)`
  width: 60px;
  margin: 0 auto;
`

const StyledServicesContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
`

const StyledService = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 10px 0;
  width: 100%;

  p {
    color: #da1e35;
    font-size: 18px;
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
    width: auto;
  }
`

const IndexPage = ({ data }) => (
  <>
    <StyledHeroImage fluid={data.hero_image.childImageSharp.fluid} />
    <StyledContentWrapper
      image={data.maps_background.childImageSharp.fluid.src}
    >
      <StyledAboutCompanySection>
        <StyledSectionTitle>O FIRMIE</StyledSectionTitle>
        <p>
          Firma Marcus to rodzinna firma, która na rynku działa nieprzerwanie od
          1991 roku.
        </p>
        <p>
          Firma Marcus wychodząc naprzeciw oczekiwaniom Klienta i czyniąc swą
          ofertę bardziej atrakcyjną i kompleksową, oferuje usługi w zakresie
          lakierowania, tłocznictwa, druku oraz nanoszenia klejów przemysłowych
          na różnego rodzaju powierzchnie, a także spedycji.
        </p>
        <p>
          Jakość usług świadczonych przez firmę potwierdzają certyfikaty ISO, a
          zdobyte doświadczenie wykorzystywane jest we współpracy z największymi
          producentami sprzętu AGD oraz branży automotive. Firma Marcus korzysta
          ze środków Unii Europejskiej
        </p>
      </StyledAboutCompanySection>
      <StyledAboutCompanySection>
        <StyledSectionTitle>TUTAJ NAS ZNAJDZIESZ</StyledSectionTitle>
        <StyledLocationImage fluid={data.location.childImageSharp.fluid} />
      </StyledAboutCompanySection>
      <StyledAboutCompanySection>
        <StyledSectionTitle>OFERUJEMY USŁUGI Z ZAKRESU:</StyledSectionTitle>
        <StyledServicesContainer>
          <StyledService>
            <StyledMagnifierImage
              fluid={data.magnifier.childImageSharp.fluid}
            />
            <p>LAKIEROWANIA</p>
          </StyledService>
          <StyledService>
            <StyledMagnifierImage
              fluid={data.magnifier.childImageSharp.fluid}
            />
            <p>OBRÓBKI</p>
          </StyledService>
          <StyledService>
            <StyledMagnifierImage
              fluid={data.magnifier.childImageSharp.fluid}
            />
            <p>NADRUKU</p>
          </StyledService>
          <StyledService>
            <StyledMagnifierImage
              fluid={data.magnifier.childImageSharp.fluid}
            />
            <p>SPEDYCJI</p>
          </StyledService>
        </StyledServicesContainer>
      </StyledAboutCompanySection>
    </StyledContentWrapper>
  </>
)

export const query = graphql`
  {
    hero_image: file(name: { eq: "hero_image" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    location: file(name: { eq: "location" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    maps_background: file(name: { eq: "maps_background" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    magnifier: file(name: { eq: "magnifier" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
